import $ from "jquery";
import {
    welcome_background_color,
    solutions_background_color,
    technologies_background_color,
    team_background_color,
    footer_background_color,
} from "./constants";

let controller;
let slideScene;
let solutionsScene;
let technologiesScene;
let teamScene;
let footerScene;
let desktop = $(window).width() >= 1080;
let mobile = $(window).width() <= 767;
let size = window.innerWidth > window.innerHeight ? window.innerWidth * 1.3 : window.innerHeight * 1.3;
let sizeLeft = window.innerWidth > window.innerHeight ? window.innerWidth * 1.05 : window.innerHeight * 1.05;
let nav = document.querySelector(".nav-header-container");
gsap.config({
    nullTargetWarn: false,
});
gsap.registerPlugin(TextPlugin);
$(document).ready(function () {

    animateSlides();
});


function animateSlides() {
    //Init Controller
    controller = new ScrollMagic.Controller();

    const slides = document.querySelectorAll(".slide");
    let background = document.querySelectorAll(".slide-background");
    ////Loop over each slide ////
    slides.forEach((slide, index, slides) => {
        const slideTl = gsap.timeline({
            defaults: {duration: .6, ease: "power2.easeInOut"}
        });
        //Add Animations here
        let animated_subtitle = `.animated-subtitle-${slide.id}`;
        let animated_logo = `.animated-logo-${slide.id}`;
        let animated_desc = `.animated-desc-${slide.id}`;
        let animated_img = `.animated-img-${slide.id}`;
        let animated_btn = `.animated-btn-${slide.id}`;
        let floating_text = `.floating-text-${slide.id}`;
        let floating_text_line = `.line-${slide.id}`;

        slideTl.fromTo(animated_logo, 1, {scale: 2.6, y: "-50%", opacity: 0, rotate: 10}, {
            scale: 1,
            y: "0%",
            opacity: 1,
            rotate: 0
        });
        slideTl.fromTo(animated_subtitle, {y: "-50%", opacity: 0}, {y: "0%", opacity: 1}, '-=0.4');
        slideTl.fromTo(animated_desc, {y: "-10%", opacity: 0}, {y: "0%", opacity: 1}, '-=0.5');
        slideTl.fromTo(animated_btn, {x: "-20%", opacity: 0}, {x: "0%", opacity: 1}, '-=0.5');
        slideTl.fromTo(floating_text, 1.2, {opacity: 0}, {opacity: .05}, '-=0.8');
        slideTl.fromTo(floating_text_line, 1.4, {height: 0}, {height: '20vh'}, '-=1.35');
        slideTl.fromTo(animated_img, {x: "20%", opacity: 0}, {
            x: "0%",
            opacity: 1
        }, slide.id === 'technologies' ? '-=1.5' : '-=0.5');

        //Create Scene
        slideScene = new ScrollMagic.Scene({
            triggerElement: slide,
            triggerHook: .25,
            reverse: false,
            offset: -150
        })
            .setTween(slideTl)
            .addTo(controller);


    });


    //// Welcome Slide Animations Here ////
    let welcome = document.getElementById('welcome');
    const welcomeTl = gsap.timeline({
        defaults: {duration: 2, ease: "power2.easeInOut"}
    });
    welcomeTl.fromTo('.particles-wrapper', 4, {opacity: 0}, {opacity: 0.6}, '0.5');
    welcomeTl.fromTo('.project-logo', .9, {opacity: 0, scale: 1.1}, {opacity: 1, scale: 1, stagger: 0.1}, '-=4.2');


    //// Solutions Slide Animations Here ////
    let solutions = document.getElementById('solutions');
    const solutionsTl = gsap.timeline({
        defaults: {duration: .4, ease: "power2.easeInOut"}
    });
    solutionsTl.fromTo(background, .6, {backgroundImage: welcome_background_color}, {backgroundImage: solutions_background_color});

    solutionsScene = new ScrollMagic.Scene({
        triggerElement: solutions,
        triggerHook: 0.25,
        offset: -150
    })

        .setTween(solutionsTl)
        .addTo(controller);


    //// Technologies Slide Animations Here ////
    let technologies = document.getElementById('technologies');

    const technologiesTl = gsap.timeline({
        defaults: {duration: .4, ease: "power2.easeInOut"}
    });
    technologiesTl.fromTo(background, .6, {backgroundImage: solutions_background_color}, {backgroundImage: technologies_background_color});
    technologiesScene = new ScrollMagic.Scene({
        triggerElement: technologies,
        triggerHook: 0.25,
        offset: -150
    })
        .setTween(technologiesTl)
        .addTo(controller);

    //// Team Slide Animations Here ////
    let team = document.getElementById('team');
    const teamTl = gsap.timeline({
        defaults: {duration: .4, ease: "power2.easeInOut"}
    });
    teamTl.fromTo(background, .6, {backgroundImage: technologies_background_color}, {backgroundImage: team_background_color});
    teamScene = new ScrollMagic.Scene({
        triggerElement: team,
        triggerHook: 0.25,
        offset: -150
    })

        .setTween(teamTl)
        // .addIndicators({
        //     colorStart: "white",
        //     colorTrigger: "white",
        //     name: "team"
        // })
        .addTo(controller);


//// Footer Slide Animations Here ////
    let footer = document.getElementById('footer');

    const footerTl = gsap.timeline({
        defaults: {duration: .4, ease: "power2.easeInOut"}
    });
    footerTl.fromTo(background, .6, {backgroundImage: team_background_color}, {backgroundImage: footer_background_color});

    footerScene = new ScrollMagic.Scene({
        triggerElement: footer,
        triggerHook: 0.25,
        offset: -150
    })
        .setTween(footerTl)
        .addTo(controller);


    //Navigation Bar Animations Here
    const navTl = gsap.timeline({
        defaults: {duration: 1, ease: "power2.easeInOut"}
    });
    navTl.fromTo(nav, 1.2, {y: '-40%', opacity: 0}, {y: '0%', opacity: 1});


}

const burger = document.querySelector(".burger");
const solutions_btn = document.querySelector(".animated-btn-solutions");
const burger_off = document.querySelector("#form-toggle");


function _throttle(callback, limit) {
    var wait = false;
    return function () {
        if (!wait) {
            callback.call();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, limit);
        }
    };
}

const triggerClick = (target) => {
    let event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    target.dispatchEvent(event);

};


function initFormAnimation() {
    let form_img = document.getElementById('eoi0FElQzSK1');
    triggerClick(form_img);
}


function navToggle() {
    let dif = Math.abs(window.innerWidth - window.innerHeight);
    if (dif < 150) {
        size = 2000;
    }
    let burger = document.getElementById('burger');
    if (!burger.classList.contains("active")) {
        burger.classList.add("active");
        gsap.to(nav, 1.2, {y: '-100%', opacity: 0, ease: 'Power2.easeInOut'});
        gsap.to(".nav-bar", 1.5, {display: "grid"});
        gsap.to(".nav-bar", 1.5, {clipPath: `circle(${size}px at 100% 0%)`, ease: 'Power1.easeInOut'});

        gsap.to(".nav-bar-left", 1.5, {
            clipPath: `circle(${sizeLeft}px at 0% 0%)`,
            delay: .5,
            ease: 'Power1.easeInOut'
        });
        gsap.fromTo('#form-toggle', 1.2, {x: '150%', opacity: 0, ease: 'Power2.easeInOut'}, {
            x: '0%',
            opacity: 1,
            ease: 'Power2.easeInOut'
        });
        gsap.to("#fp-nav", 1.2, {x: '200%', opacity: 0, rotate: -10, ease: 'Power2.easeInOut'});
        gsap.fromTo('#form-id', 1.1, {x: '25%', opacity: 0, ease: 'Power2.easeInOut'}, {
            x: '0%',
            opacity: 1,
            ease: 'Power2.easeInOut',
            delay: .5
        });

        fullpage_api.setAllowScrolling(false);
        fullpage_api.setKeyboardScrolling(false);
        initFormAnimation();

    } else {
        burger.classList.remove("active");
        gsap.to(nav, 1.2, {y: '0%', opacity: 1, delay: 1});
        gsap.to(".nav-bar", 1, {display: "none", delay: 1});
        gsap.to(".nav-bar", 1.5, {clipPath: "circle(0px at 100% 0%)", delay: .4, ease: 'Power1.easeInOut'});
        gsap.to(".nav-bar-left", 1.5, {clipPath: "circle(0px at 0% 0%)", ease: 'Power1.easeInOut'});
        gsap.to('#form-toggle', 1.2, {x: '100%', opacity: 0, ease: 'Power2.easeInOut'});
           gsap.to('#form-id', 0.8, {x: '25%', opacity: 0, ease: 'Power1.easeInOut',delay:0.2});

        gsap.to("#fp-nav", 1.2, {x: '0%', opacity: 1, rotate: 0, ease: 'Power2.easeInOut'});
        fullpage_api.setAllowScrolling(true);
        fullpage_api.setKeyboardScrolling(true);
    }
}

//Event Listeners
$(window).resize(function () {
    size = window.innerWidth > window.innerHeight ? window.innerWidth * 1.3 : window.innerHeight * 1.3;
    sizeLeft = window.innerWidth > window.innerHeight ? window.innerWidth * 1.05 : window.innerHeight * 1.05;
    let dif = Math.abs(window.innerWidth - window.innerHeight);
    if (dif < 150) {
        size = 2000;
    }
    let burger = document.getElementById('burger');

    if (burger.classList.contains("active")) {
        gsap.to(".nav-bar", 0, {clipPath: `circle(${size}px at 100% 0%)`});
        gsap.to(".nav-bar-left", 0, {clipPath: `circle(${sizeLeft}px at 0% 0%)`});

    }
});

burger.addEventListener("click", _throttle(navToggle, 1000));
solutions_btn.addEventListener("click", _throttle(navToggle, 1000));
burger_off.addEventListener("click", _throttle(navToggle, 1000));


