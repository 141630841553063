import $ from "jquery";

$(document).ready(function () {
    new fullpage('#fullpage', {
        licenseKey: 'FEEA46B0-39B14F0B-85079ECA-064DF323',
        autoScrolling: true,
        sectionSelector: '.slide',
        slideSelector: '.slide-slide',
        controlArrows: true,
        fixedElements: '.cursor-container',
        navigation: true,
        navigationPosition: 'right',
        lazeLoading:true,
        anchors: ['about-us','software-development', 'our-technologies', 'our-team', 'contacts'],
        navigationTooltips: ['About Us', 'Software Development','Technologies', 'Our Team', 'Contacts'],
        onLeave: function (origin, destination) {
            if (destination.anchor === 'our-technologies') {
                initTechnologiesAnimation();
            }


        }
    });
});
function initTechnologiesAnimation() {
    let img = document.getElementById('elE83vYi0IL1');
        let event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    img.dispatchEvent(event);
}