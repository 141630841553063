let toggler = document.querySelector("#lang-toggle");

import Cookies from 'js-cookie';

function langToggle() {
    Cookies.set('user_language', 'en');

    setTimeout(() => {
        if (location.pathname.includes('/ru')) {
            location.pathname = `/`;
        } else {
            location.reload();
        }
    }, 100);
}

toggler.addEventListener("click", langToggle);
